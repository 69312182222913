// =============================================
// circular progress bar

.circular-progress-bar {
  position: relative;

  --size: 64px;
  --half-size: calc(var(--size) / 2);

  // making the circle stroke bigger or smaller -> base = 3.5
  --stroke-width: calc(var(--size) * 0.1);

  // from 1 to 0.9 -> placing the circle a little closer to the icon
  --radius: calc((var(--size) * 1 - var(--stroke-width)) / 2);

  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);

  animation: progress-animation 5s linear 0s 1 forwards;
}

.circular-progress-bar circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress-bar circle.bg {
  // stroke: rgba(0, 0, 0, 0.4);
  stroke: #505050;
}

.circular-progress-bar circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 1s;
  stroke: white;
}

@keyframes progress-animation-1 {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}

// =============================================
