// =================================================
// Page

// These are full screen components with black backgrounds,
// that could also be considered as Pages that we can navigate to.

.page-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  .page {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding: 0;

    background-color: #121212;

    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 20px;

    padding: 16px; // 16px 24px;

    // setup fullscreen safe-area
    padding-top: calc(var(--safe-top) + 0px);
  }
}

// =================================================
// Modal Drawer

// These are drawer overlays that appear over the full screen modals

.modal-drawer {
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;

  &.slide-down {
    overflow: hidden;
  }

  .modal-drawer-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background-color: black;
    padding: 24px;
    padding-top: 48px;

    // border: 1px solid #444;
    // border-bottom: unset;

    border-radius: 16px 16px 0 0;

    .modal-drawer-button-close {
      pointer-events: auto;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .drawer-layout {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .drawer-header {
        position: relative;
        width: 100%;

        .drawer-header-title {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 32px;
          padding-top: 20px;
          z-index: 1;
        }

        .drawer-header-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 18px;
          color: #666;
          padding: 16px 12px;
          line-height: 28px;
        }
      }

      .drawer-body {
        padding-bottom: 28px;
      }
    }
  }
}

// =================================================
// Modal Overlay

// These are full screen overlays that appear over the full screen modals by fade

.modal-overlay {
  z-index: 998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0;

  --blur-amount: 32px;
  background-color: var(--navbar-color);
  -webkit-backdrop-filter: blur(var(--blur-amount));
  backdrop-filter: blur(var(--blur-amount));

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-overlay-box {
    width: 100%;
    height: fit-content;

    .overlay-layout {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .overlay-header {
        position: relative;
        .drawer-header-icon {
          img {
            width: 100px;
            height: 100px;
          }
        }

        .overlay-header-title {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 40px;
          padding-top: 24px;
          color: white;
        }

        .overlay-header-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 18px;
          color: #666;
          padding: 16px 12px;
          line-height: 28px;
        }
      }

      .overlay-body {
        padding-bottom: 28px;
      }
    }
  }
}
