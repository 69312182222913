// =============================================
// Tiktok Page

// .page-container {
//   background-color: orange;
// }

.page.tiktok {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0;

  background-color: transparent;

  height: 100%;

  overflow-y: hidden;
  overflow-x: hidden;
}

// =============================================
// Carousel / Feed

.carousel-container {
  width: 100%;
  height: 100%;
  position: relative;

  &.swipe-enabled {
    pointer-events: auto;
  }

  &.swipe-disabled {
    pointer-events: none;
  }

  overflow: hidden;
  margin-top: 0px;

  .empty-carousel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .carousel-root {
    background-color: transparent;
    width: 100%;
    height: 100%;

    // important: note that the carousel slider (FeedItem.scss)
    // needs to be 100svh based absolute size for carousel to render properly
    .carousel {
      button {
        display: none;
      }

      // this is the slide list wrapper
      .slider-wrapper {
        // this is the slide list
        ul {
          padding: 0;
          margin: 0;

          // this is each slide
          li {
            list-style: none;
          }
        }
      }
    }
  }
}
