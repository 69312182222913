// =============================================
// container for whole tabs and lists with items

.meme-details-tabs-container {
  width: 100%;
  margin-top: 8px;
}

// =============================================
// container for list with items on each tab

.items-area {
  // background-color: red;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  padding-bottom: 64px;
}

// ==========================================================================================
// section content for each tab

.meme-details-section {
  // background-color: grey;
  font-family: PPPangramSansRounded-Medium;
  font-size: 14px;
  line-height: 22px;
  color: #777;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  height: fit-content;
}

// ==========================================================================================
// section 1 - market stats

// -------------
// mcap values

.item-mcap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;

  width: 100%;

  padding-bottom: 8px;

  .item-mcap-section {
    width: 50%;

    .item-mcap-value {
      font-family: PPPangramSansRounded-Bold;
      font-size: 24px;
      color: white;
    }

    .item-mcap-label {
      font-family: PPPangramSansRounded-Medium;
      font-size: 12px;
      color: #777;
    }
  }

  .item-mcap-separator {
    width: 1px;
    height: 42px;
    background-color: #555;
  }
}

// -------------
// progress area

.item-progress-area {
  // background-color: blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  &.claim-state {
    gap: 9.5px;
  }

  .item-progress-label {
    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
    color: white;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;

    line-height: 1.3;

    &.w80 {
      width: 80%;
    }

    &.s12 {
      font-size: 12px;
    }

    &.s13 {
      font-size: 13px;
    }

    &.s16 {
      font-size: 16px;
    }

    &.bold {
      font-family: PPPangramSansRounded-Bold;
    }

    &.blue {
      color: #0098ea; // blue
    }

    &.yellow {
      color: #f5c005; // yellow'
    }

    &.grey {
      color: #777; // grey
    }

    &.black {
      color: black;
    }

    &.mcap {
      flex-wrap: wrap;
      width: 100%;
      padding-top: 4px;
    }

    &.flavor {
      color: #7f7f7f;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      strong {
        color: #fff;
        font-style: normal;
        font-weight: bold;
      }

      em {
        color: #0098ea;
        font-style: normal;
        font-weight: bold;
      }
    }
  }

  .item-progress-title {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }

  .item-progress-footer {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    flex-wrap: wrap;
  }

  .btn-claim {
    margin-top: 4px;
    height: 50px;
    font-size: 14px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;

    color: #3e3e3e;

    strong {
      color: black;
      font-weight: bold;
    }
  }
}

// ==========================================================================================
// Section 2 - About

.meme-details-section.about {
  overflow-y: scroll;
  overflow-x: hidden;

  .about-section-scroller {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;

    width: 100%;
    height: fit-content;

    padding: 16px 0;

    .about-section {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 6px;

      width: 100%;

      &.edit-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .edit-info {
          width: 30%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: end;
          gap: 8px;

          .about-section-title {
            font-family: PPPangramSansRounded-Bold;
            font-size: 12px;
            color: white;
          }
          img {
            width: 12px;
            height: 12px;
            margin-top: -1px;
          }
        }
      }

      .about-section-title {
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        color: white;
        text-align: left;
      }

      .about-section-text {
        font-family: PPPangramSansRounded-Medium;
        font-size: 13px;
        color: #777;
        text-align: left;
      }

      .about-social-buttons {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
        gap: 8px;
        flex-wrap: wrap;

        margin: auto; // visual correction

        .about-social-button {
          width: calc(50% - 4px);

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;

          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          color: white;

          background-color: #232323;
          border-radius: 90px;

          padding: 4px 12px;
          padding-bottom: 2px;
          padding-right: 14px;

          .icon {
            width: 16px;
            height: 16px;
            margin-top: -3px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .about-stats-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;

        width: 100%;

        .about-stats-row-content {
          // pointer-events: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;

          img {
            width: 12px;
            height: 12px;
          }

          .label {
            font-family: PPPangramSansRounded-Semibold;
            font-size: 13px;
            color: white;

            &.grey {
              color: #777;
            }
          }
        }
      }
    }
  }
}

// ------------------------------------------------------------------------------------------
// Copy success message

.copy-success {
  position: absolute;
  top: -28px;
  right: -8px; // otherwise disappears under page overflow horizontal padding...
  z-index: 999;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 12px;
  background-color: black;
  border: 1px solid #222;

  // position: absolute;
  // top: -44px;
  // left: -32px; // otherwise disappears under page overflow horizontal padding...

  padding: 2px 8px;
  // padding-right: 10px;
  box-sizing: border-box;

  .copy-success-icon {
    width: 14px;
    height: 14px;
    img {
      width: 14px;
      height: 14px;
    }
  }

  .copy-success-text {
    font-family: PPPangramSansRounded-Medium;
    font-size: 13px;
    color: white;
    padding-top: 2px;
    box-sizing: border-box;
  }
}

// ==========================================================================================
// Section 3 - Transactions

.meme-details-section.transactions {
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: start;
}

// --------------------------------------------------------------
// transactions currency filter

.transactions-currency-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0;

  width: 100%;
  padding: 8px 0;
  padding-top: 16px;

  .transactions-currency-filter-title {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 14px;
    color: white;
  }

  .transactions-currency-toggle-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;

    width: 140px;

    .transactions-currency-toggle-button {
      width: 72px;
      height: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &.left {
        border-top-left-radius: 90px;
        border-bottom-left-radius: 90px;
      }

      &.right {
        border-top-right-radius: 90px;
        border-bottom-right-radius: 90px;
      }

      background-color: #232323;
      color: white;

      &.selected {
        background-color: white;
        color: black;
      }

      img {
        width: 16px;
        height: 16px;
      }
      .transactions-currency-toggle-label {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 11px;
        padding-top: 2px;
      }
    }
  }
}

// --------------------------------------------------------------
// transactions list

.meme-details-transactions-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.meme-details-transaction-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0;

  width: 100%;
  padding: 12px 0;

  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  font-family: PPPangramSansRounded-Semibold;
  font-size: 14px;
  color: white;

  .transaction-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;

    .image {
      width: 34px;
      height: 34px;

      img {
        width: 100%;
        border-radius: 90px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 0;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 13px;

      .name {
        text-align: left;
        margin-top: 2px;
      }

      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 4px;

        margin-top: -2px;

        .type {
          font-size: 12px;
          &.buy {
            color: #09d0a8;
          }
          &.sell {
            color: #d00934;
          }
        }

        .icon {
          margin-top: -3px;
        }

        // .icon {
        //   width: 15px;
        //   height: 15px;
        //   margin-top: -3px;
        //   img {
        //     width: 15px;
        //     height: 15px;
        //   }
        // }

        .value {
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          color: white;

          &.gold {
            color: #f5c005;
          }
        }
      }
    }
  }

  .transaction-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    gap: 0;

    .value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 4px;
      width: 100%;

      .value-label {
        font-family: PPPangramSansRounded-Semibold;
        font-size: 13px;
        margin-top: 2px;
      }

      .pending-spinner {
        position: relative;
        width: 20px;
        height: 20px;
        margin-left: 8px;
        border: 3px solid #666; // Light gray border
        border-top: 3px solid #09d0a8; // Green color for the spinner
        border-radius: 50%;
        opacity: 1;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
    .time {
      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;
      color: #777;
      margin-top: -2px;
    }
  }
}

// ==========================================================================================
