// =================================================
// Modal Drawer

// These are drawer overlays that appear over the full screen modals

.modal-drawer {
  pointer-events: none;
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 1;

  visibility: visible;
  &.hidden {
    visibility: hidden;
  }

  .modal-drawer-backdrop {
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);

    &.noModal {
      pointer-events: none;
      background-color: transparent;
    }

    &.skipBackgroundClose {
      pointer-events: none;
    }

    transition: all 250ms ease-in-out;
    opacity: 0;
    &.in {
      opacity: 1;
    }
    &.out {
      opacity: 0;
    }
  }

  .modal-drawer-box {
    z-index: 2;
    pointer-events: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background-color: #121212;

    padding: 24px;
    padding-top: 32px;

    border-radius: 16px 16px 0 0;

    max-height: 100svh;

    // setup fullscreen safe-area
    max-height: calc(100svh - (var(--safe-top) + 48px));

    transition: all 250ms ease-in-out;

    transform: translateY(32px);
    opacity: 0;
    &.in {
      transform: translateY(0);
      opacity: 1;
    }
    &.out {
      transform: translateY(32px);
      opacity: 0;
    }

    .modal-drawer-button-close {
      z-index: 999;
      pointer-events: auto;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;

      width: 32px;
      height: 32px;
    }

    .drawer-layout {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .drawer-header {
        position: relative;
        width: 100%;

        .drawer-header-icon {
          img {
            max-height: 190px;
          }
        }

        .drawer-header-title {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 32px;
          padding-top: 20px;
          z-index: 1;
        }

        .drawer-header-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 18px;
          color: #666;
          padding: 16px 12px;
          line-height: 28px;

          &.left {
            text-align: left;
            padding-left: 16%;
            padding-right: 16%;
            font-size: 16px;
          }
        }
      }

      .drawer-body {
        padding-bottom: 28px;
      }
    }
  }

  .backdrop-hitbox {
    position: 'absolute';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
