$blur: 11px;

.modal-drawer.drawerMigration {
  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .modal-drawer-box {
    background-color: unset; // rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    padding-top: 18px;

    animation: unset;
  }
}

.drawer-migration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  .drawer-migration-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .drawer-migration-loading {
      width: 100%;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 32px;
      padding: 32px;

      .drawer-migration-checking {
        font-family: PPPangramSansRounded-Bold;
        font-size: 24px;
        color: white;
        line-height: 1.2;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      .drawer-migration-loading-bars {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .progress-bar-container-v {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 8px;

          .progress-bar-text {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 13px;
            color: white;
            line-height: 1.2;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            opacity: 0.5;
          }

          .progress-bar-container-h {
            width: 100%;
            height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .progress-bar {
              width: 100%;
              position: relative;
              height: 12px;
              border-radius: 100px;

              .progress-bar-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                background-color: #222;
              }

              .progress-bar-value {
                position: absolute;
                height: 100%;
                border-radius: 100px;
                &.yellow {
                  background-color: #f5c005;
                }
              }
            }

            .progress-bar-icon {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 8px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.drawer-migration-claim-container {
  font-family: PPPangramSansRounded-Regular;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: -15px;
  position: relative; /* Added to contain absolutely positioned children */
}

.drawer-migration-claim-image {
  width: 150px;
  height: auto;
  position: relative; /* Default positioning for large screens */
  z-index: 1;
}

.drawer-migration-claim-section,
.drawer-migration-claim-section-2 {
  margin-bottom: 3vh;
}

.drawer-migration-claim-heading {
  font-family: PPPangramSansRounded-Bold;
  font-size: 22px;
  margin-bottom: 0.7vh;
}

.drawer-migration-claim-text {
  font-family: PPPangramSansRounded-Regular;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.5;
  margin-bottom: 0.3vh;
}

.drawer-migration-claim-text-2 {
  font-family: PPPangramSansRounded-Bold;
  font-size: 14px;
  line-height: 1.6;
  opacity: 0.5;
  margin-bottom: 1vh;
}

.drawer-migration-claim-rewards-title {
  font-family: PPPangramSansRounded-Bold;
  font-size: 24px;
  color: #ffb900;
}

.drawer-migration-claim-rewards-amount {
  font-family: PPPangramSansRounded-Bold;
  font-size: 28px;
  margin-bottom: 1.5vh;
}

.drawer-migration-claim-button {
  max-width: 180px !important;
  height: 48px !important;
  min-height: 48px !important;
  font-size: 14px !important;
  margin-top: 2vh !important;
}

.drawer-migration-claim-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
