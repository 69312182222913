// ==========================================================================================
// Sidebar

.sidebar {
  // background-color: red;
  z-index: 4;
  position: absolute;
  right: 16px;

  // todo: because owned/points goes to double line
  // todo: we need to make vertical position relative to ui height
  bottom: 114px; // 115px;

  width: 36px;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: end;
  gap: 32px;

  .sidebar-item {
    cursor: pointer;
    pointer-events: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 8px;

    .sidebar-image {
      width: fit-content;
      height: fit-content;
      img {
        width: unset;
        height: unset;
      }

      .label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        color: white;
        width: 2rem; /* Set a consistent width for the label */
        text-align: center; /* Center the text inside the label */
        display: block; /* Ensure the label remains on its own line */
        margin: 0 auto; /* Center the label horizontally within its container */

        margin-top: 4px;
      }
    }

    &.author {
      .creator-image {
        img {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &.share {
      margin-bottom: -2px;
    }

    &.button-more {
      justify-content: center;
      gap: 0;

      font-family: PPPangramSansRounded-Bold;
      font-size: 12px;
      width: 64px;
      height: 28px;

      margin-right: 24px;
    }
  }
}
