.modal-drawer.drawerTradingCreationSuccess {
  .modal-drawer-backdrop {
    background: linear-gradient(180deg, #555 8.85%, #000 97.33%) !important;
  }

  .modal-drawer-box {
    background-color: unset; // rgba(255, 0, 0, 0.5);
    position: absolute;
    bottom: 0;

    max-height: calc(100svh - var(--safe-top) - 0px) !important;
    padding-top: 0;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    animation: unset;

    border: none !important;
  }
}

// ------------------------------

.drawer-create-transaction {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

// ------------------------------

.header-empty {
  width: 100%;
}

// ------------------------------

// offchainToken frame and image

.create-token-container {
  z-index: 1;
  position: relative;
  padding-bottom: 8px;

  width: 100%;
  padding: 0 48px;

  .create-token-frame {
    position: relative;
    z-index: 0;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

    .create-token-image {
      z-index: 2;
      position: absolute;
      width: 90%;
      height: 85%;
      border-radius: 12px;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      transform: translateY(-15px);

      img {
        width: unset;
        height: 100%;
        object-fit: cover;
      }
    }

    .create-ticker-name {
      position: absolute;
      width: 100%;

      top: 66%;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 26px;

      background: linear-gradient(
        96.84deg,
        #ffffff 8.85%,
        rgba(255, 255, 255, 0) 97.33%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

// ------------------------------
// footer / info

.drawer-create-transaction-header {
  padding-bottom: 0 !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px !important;

  .drawer-create-transaction-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }

  .drawer-create-transaction-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .drawer-create-transaction-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 28px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      &.yellow {
        font-size: 28px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .drawer-create-transaction-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    line-height: 22px;
    color: #777;
  }

  .drawer-create-transaction-reward {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    font-family: PPPangramSansRounded-SemiBold;
    font-size: 14px;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 0px;
    }
    .value {
      color: white;
    }
    .currency {
      color: #f5c005;
    }
  }
}

// give and get

.drawer-create-share-giveAndGet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin-top: 8px;
  margin-bottom: 16px;

  .drawer-create-share-giveAndGet-text {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 16px;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    &.separator {
      opacity: 0.5;
      width: fit-content;
      height: 72%;
    }
  }
}

.footer-buttons {
  flex-direction: column !important;
  gap: 16px !important;

  .button-share {
    font-size: 14px;
  }

  .button-share-close {
    text-decoration: underline;
    font-size: 14px;
  }
}
