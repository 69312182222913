.page.meme-details {
  background-color: #121212;

  // avoid scrolling full meme-details page
  overflow-y: hidden;

  padding-left: 16px;
  padding-right: 16px;
}

.meme-details-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;

  width: 100%;

  margin-top: 8px;

  // ====================================================================

  .meme-details-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;

    width: 100%;

    padding-top: 4px;

    // --- TICKER + NAME

    .meme-details-header-title {
      // background-color: red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .meme-details-header-title-label {
        font-family: PPPangramSansRounded-Medium;
        font-size: 14px;
        color: white;

        &.blue {
          color: #0098ea;
        }
      }
    }

    // --- IMAGE

    .meme-details-header-image-row {
      // background-color: blue;
      margin-left: 32px;

      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
      gap: 8px;

      .meme-details-header-image {
        width: 56px;
        height: 56px;
        border-radius: 90px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .meme-details-header-share {
        width: 24px;
        height: 24px;
        border-radius: 90px;
        background-color: rgba(255, 255, 255, 0.1);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 50%;
          margin-top: -3px;
        }
      }
    }
    // --- MARKET CAP

    .meme-details-header-mcap {
      // background-color: red;

      .meme-details-header-mcap-value {
        // background-color: orange;
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 44px;
        color: white;
      }

      .meme-details-header-mcap-label {
        // background-color: blue;
        font-family: PPPangramSansRounded-Medium;
        font-size: 12px;
        color: #777;
      }
    }
  }

  // ====================================================================
}
