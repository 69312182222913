.drawer-details.gift-back {
  .gift-back-button {
    font-size: 16px;
    width: auto;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    text-align: center;
  }

  .gift-back-header {
    // background-color: red;
    padding: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    padding-top: 12px;
    padding-bottom: 32px;

    // =========================================
    // title and sender

    .gift-back-titlesender {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      .gift-back-title {
        font-family: PPPangramSansRounded-Bold;
        color: white;
        font-size: 24px;
        margin-top: 16px;
      }

      .gift-back-sender {
        font-family: PPPangramSansRounded-Medium;
        color: white;
        font-size: 14px;
        color: #777;
      }
    }

    // =========================================
    // meme-info (meme-icon, value, tickerName)

    .gift-back-meme-info {
      // background-color: green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;

      .row {
        // background-color: blue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .value {
          font-family: PPPangramSansRounded-Bold;
          font-size: 24px;
          color: white;
          padding-top: 3px;
        }

        .ticker {
          font-family: PPPangramSansRounded-Bold;
          font-size: 24px;
          color: #f5c005; // yellow'
          padding-top: 3px;
        }
      }

      .currency {
        // background-color: orange;
        font-family: PPPangramSansRounded-Medium;
        font-size: 14px;
        color: #777;
      }
    }
    // =========================================
    // swap-info

    .gift-back-swap-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .swap-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .swap-label {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 13px;
          color: #777;
        }
        .swap-value {
          font-family: PPPangramSansRounded-Bold;
          font-size: 18px;
        }
      }
    }
    // =========================================
  }
}
