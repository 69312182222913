.lc-container {
  // background-color: red;
  position: relative;
  width: 100%;
  height: fit-height;

  .lc-overlay-test {
    // background-color: rgba(255, 0, 0, 0.5);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .lc-marker {
      &.inactive {
        display: none;
      }

      display: block;

      // background-color: rgba(255, 0, 255, 0.5);
      z-index: 3;
      pointer-events: auto;
      position: absolute;
      top: 0;
      left: 0;

      width: 100px;

      transition: transform 0.35s ease-in-out;

      &.right {
        padding-left: 8px;
        padding-right: 0;
        text-align: left;
        transform: translate(0px, 0px);
      }
      &.left {
        padding-right: 8px;
        padding-left: 0;
        text-align: right;
        transform: translate(-100px, 0px);
      }

      .lc-marker-label {
        position: absolute;

        font-family: PPPangramSansRounded-Medium;
        font-size: 10px;
        color: #ccc;

        padding: 2px 4px;
        border-radius: 2px;
        width: fit-content;

        // background-color: #222;
        // color: #eee;

        &.right {
          left: 8px;
        }
        &.left {
          right: 8px;
        }

        &.yellow {
          top: 14px;

          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          color: #f5c005;

          // background-color: #f5c005;
          // color: black;
        }
      }
    }
  }
}
