// =============================================
// Feed Item - Top UI (Fixed outside carousel)

.feed-ui-top {
  pointer-events: none;
  z-index: 996;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  // setup fullscreen safe-area
  padding-top: calc(var(--safe-top) + 0px);

  &.invisible {
    visibility: hidden;
  }

  // new top gradient
  .feed-ui-top-gradient {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 78px; // 98px; // 198px;
    opacity: 0.8;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );

    // setup fullscreen safe-area -> note that this will be zero when there is no --safe-top available (api less than 8.0)
    // therefore, we are passing extra 'desktop' class from javascript to render gradient on desktop simulators
    height: calc(var(--safe-top) + 78px + 40px);
    &.desktop {
      height: 120px;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  .feed-ui-top-content {
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 16px;
    padding-top: 8px; // setup fullscreen safe-area

    transition: opacity 350ms ease-out;
  }

  // ===========================================================================
  // Left - empty area

  .feed-ui-top-left-area {
    pointer-events: auto;
    justify-content: start;
    gap: 4px;
    width: 100px;
    height: 40px;
  }

  // ===========================================================================
  // Center - Categories / Filters / Tabs

  .feed-ui-top-categories {
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .feed-ui-top-category {
      height: 40px;
      padding: 0 8px;

      .feed-ui-top-category-label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
        opacity: 0.5;

        border-bottom: unset;
      }

      &.selected {
        .feed-ui-top-category-label {
          opacity: 1;
          border-bottom: 1px solid white;
        }
      }
    }
  }

  // ===========================================================================
  // Right - Search area

  .feed-ui-top-search {
    justify-content: end;
    width: 100px;

    // setup fullscreen safe-area
    margin-top: 4px;
    height: 40px;

    .feed-ui-top-search-button {
      pointer-events: auto;
      width: 30px;
      height: 30px;
      img {
        height: 100%;
      }
    }
  }
}
