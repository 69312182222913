// ====================================================================
// ProfilePage

.page.profile {
  gap: 8px;

  // setup fullscreen safe-area
  // padding-top: calc(var(--safe-top) + 16px);
  padding-top: 0;

  // avoid scrolling full meme-details page
  overflow-y: hidden;

  padding-left: 16px;
  padding-right: 16px;
}

// ====================================================================
// profile header

.profile-header-gradient {
  position: absolute;
  top: 0;

  z-index: 0;
  width: 100%;
  height: 240px;

  &.telegram {
    height: 320px;
  }

  background: linear-gradient(180deg, #ffffff33 0%, #00000000 100%);

  &.red {
    background: linear-gradient(180deg, #ff000033 0%, #00000000 100%);
  }

  &.green {
    background: linear-gradient(180deg, #00ff0033 0%, #00000000 100%);
  }
}

// name +  carrot -> image + share -> big marketcap label

.profile-header {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 4px;

  width: 100%;

  // header padding to account for safe area
  padding-top: calc(var(--safe-top) + 0px);

  // --- NAME

  .profile-header-name-row {
    height: 32px;
    margin-left: 4px;
    &.with-carrot {
      margin-left: 16px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;

    .profile-header-name {
      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
      color: white;

      &.blue {
        color: #0098ea;
      }
    }
    .profile.header-carrot {
      width: 16px;
      height: 16px;
      img {
        width: 100%;
      }
    }
  }

  // --- IMAGE

  .profile-header-image-row {
    margin-left: 32px;
    margin-top: -2px;
    margin-bottom: 8px;

    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    gap: 8px;

    .profile-header-image {
      width: 56px;
      height: 56px;
      border-radius: 90px;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .profile-header-share {
      width: 24px;
      height: 24px;
      border-radius: 90px;
      background-color: rgba(255, 255, 255, 0.1);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
        margin-top: -3px;
      }
    }
  }

  // --- VALUE

  // profile header value

  .profile-header-value-container {
    .profile-header-value {
      margin-top: -6px;
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 48px;
      color: white;
    }

    .profile-header-value-label {
      font-family: PPPangramSansRounded-Medium;
      font-size: 12px;
      color: #777;
    }
  }
}

// ====================================================================
// label value change

.label-value-change-empty {
  // background-color: blue;
  height: 28px;
}

.label-value-change {
  // background-color: blue;
  height: 28px;
  margin-left: 4px;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .value {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 18px;
    color: #777;

    &.solid {
      border-radius: 8px; // 90px;
      padding: 3px 9px;
      padding-right: 6px;
      padding-bottom: 2px;
    }

    &.white {
      color: #ffffff; // white
      &.solid {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &.red {
      color: #d00948; // red
      &.solid {
        background-color: rgba(255, 0, 0, 0.2);
      }
    }
    &.green {
      color: #09d0a8; // green
      &.solid {
        background-color: rgba(0, 255, 0, 0.2);
      }
    }
  }
}

// ====================================================================
// Profile wallet buttons

.profile-wallet {
  width: 100%;

  margin-top: -2px; // visual correction
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .profile-button-wallet {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 8px;

    border: unset;

    padding: 0 12px;

    font-family: PPPangramSansRounded-Bold;
    font-size: 13px;
    width: 100%;
    height: 32px; // 36px;

    &.share {
      border: 1px solid rgba(255, 255, 255, 0.35);
      width: 160px;
    }

    &.connect-wallet {
      background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
      width: 160px;
    }

    &.disconnect-wallet {
      width: 160px;
    }

    .column {
      padding-top: 2px;
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: black;
    }
  }
}

// had to create this container wrapper, otherwise chart in profile becomes weirdly scrollable under bottom tabs
.chart-container-wrapper {
  width: 100%;
  height: fit-content;
}

// ====================================================================
// ProfileTokenList

.items-area {
  // background-color: gray;
  margin-top: 12px;
}

.profile-items-list-container {
  // background-color: red;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.profile-items-list {
  margin-top: 8px;
  margin-bottom: 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-bottom: 8px;

  // background-color: green;
}

// ====================================================================
// ProfileEmptyList

.profile-empty {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 24px;

  padding-top: 15%;

  .profile-empty-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .profile-empty-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 20px;
      color: white;
    }

    .profile-empty-message {
      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
      color: #777;
      line-height: 1.5;
    }
  }

  .profile-empty-button {
    width: 170px;
    height: 48px;
  }
}

// ====================================================================
