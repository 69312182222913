// =============================================
// Chart Time Interval Buttons

.time-interval-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-top: 0;
  margin-bottom: 8px;

  .time-interval-buttons-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 85%;
  }

  .time-interval-buttons-topleft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 8px;

    width: 100%;
  }

  .time-interval-buttons-topright {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 8px;

    width: 100%;
  }
}

// =============================================
// render as dropdown

select {
  &.dropdown {
    background-color: unset;
    &.telegram {
      // note: bg needs to be set for dropdown arrow to display in iphone
      background-color: #292929; // rgba(255, 255, 255, 0.01);
    }
  }

  padding: 4px 4px;
  border-radius: 6px;
  border: unset; // 1px solid #292929;
  outline: 0;
  color: white;
  text-align: right;

  white-space: nowrap;
  width: fit-content;
  min-width: 0;

  font-family: PPPangramSansRounded-SemiBold;
  font-size: 14px;
  text-align: right;

  appearance: auto;
  -webkit-appearance: auto;

  option {
    text-align: right;
  }
}

// =============================================
// render as inline buttons

.interval-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 12px;

  padding: 4px 8px;
  border-radius: 16px;
  transition: background-color 0.3s ease;
}

.interval-button.active {
  background-color: #292929;
  color: white;
}

// =============================================
