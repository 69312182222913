$slider-color: white;
$slider-color-bg: #232323;

.dotted-slider {
  width: 100%;
  padding: 2px 0;
  position: relative;

  &-labels {
    position: relative;
    height: 20px;
    margin-bottom: 4px;
  }

  &-label {
    position: absolute;
    font-family: PPPangramSansRounded-Medium;
    font-size: 12px;
    color: #777;
    transform: translateX(-50%);
    white-space: nowrap;

    &.left {
      transform: translateX(0);
    }

    &.right {
      transform: translateX(-100%);
    }
  }

  &-track {
    position: relative;
    height: 12px;
    background-color: #2a2a2a;
    border-radius: 8px;
    cursor: pointer;
  }

  &-handler {
    z-index: 1;
    position: absolute;
    top: -6px;
    left: 0px;
    width: 24px;
    height: 24px;
    border-radius: 90px;
    background-color: $slider-color;

    border: 2px solid #121212;

    display: flex;
    align-items: center;
    justify-content: center;

    &-inner-dot {
      background-color: $slider-color-bg;
      border-radius: 90px;
      width: 6px;
      height: 6px;
    }
  }

  &-fill {
    position: absolute;
    height: 100%;
    border-radius: 8px;

    // background: linear-gradient(90deg, #f5a623 0%, #f5c005 100%);
    background: white;
  }

  &-dot {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: all 0.3s ease;
    pointer-events: none;

    &-inner {
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #888;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      width: 20px;
      height: 20px;
      background-color: white;
    }

    &.end-dot {
      .dotted-slider-dot-inner {
        display: none;
      }
    }
  }
}
