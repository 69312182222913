.creator-image {
  width: 56px;
  height: 56px;
  border-radius: 90px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 2px solid #c3c3c3; // s3 - off-white

  &.s1 {
    border: 2px solid #a335ee; // s1 - purple
  }

  &.s2 {
    border: 2px solid #0070dd; // s2 - blue
  }

  img {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
