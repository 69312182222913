.tiktok-playAndFarm {
  pointer-events: none;
  // note that pointer events need to be active in this container
  // so we can tap-tap when tapgame starts

  // root container needs to be always visible
  // to avoid issues while swiping on carousel
  // opacity is changed dynamically from js syle
  transition: opacity 350ms ease-out;

  // note: this should not be necessary since is implemented in every div
  // but explicitly adding it just to be sure
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  .tiktok-playAndFarm-content {
    pointer-events: none;
    text-align: center;

    position: absolute;
    top: 45%; // 45%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 160px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    // setup fullscreen safe-area
    padding-top: calc(var(--safe-top) + 0px);
  }

  // =======================================================
  // Play Button - Centered

  .button-play-container-center {
    pointer-events: none;
    text-align: center;

    width: 100%;

    .button-play-outer {
      position: relative;
      pointer-events: auto;
      animation: blink 1.5s infinite;

      background-color: rgba(255, 255, 255, 0.25);
      border-radius: 90px;

      width: 100%;
      height: 160px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .button-play {
      // setup fullscreen safe-area
      margin-top: -150px;
      margin-left: 10px;

      background-color: rgba(255, 255, 255, 1);
      border-radius: 90px;
      width: 140px;
      height: 140px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      .button-play-content {
        align-items: center;
        justify-content: center;
        line-height: 22px;

        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: black;

        width: 100%;

        margin-top: 4px;

        // &.play {}
        // &.invite {}
      }
    }
  }

  // ==============================================
}
