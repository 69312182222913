.gift-image-circle {
  background-color: black;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border-radius: 100%;
  // border: 3px solid white;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .gift-image-circle-inner {
    background-color: #121212;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    border-radius: 100%;

    .gift-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .badge {
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translate(-50%, 50%);

      width: 25%;
      height: 25%;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
