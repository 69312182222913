.modal-drawer.drawerTiktokBuySell {
  // .modal-drawer-backdrop {}
  .modal-drawer-box {
    padding-top: 24px;
  }
}

.drawer-tiktok-buysell {
  width: 100%;
  height: 338px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;

  // drawer title

  .buysell-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    width: 100%;

    .buysell-header-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .buysell-title {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
        text-align: left;
        width: 100%;
      }

      .button-close-custom {
        pointer-events: auto;
        cursor: pointer;

        width: 32px;
        height: 32px;
      }
    }
  }

  .button-close-custom {
    pointer-events: auto;
    cursor: pointer;
    width: 32px;
    height: 32px;
  }

  // drawer tabs

  .items-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
    padding-bottom: 0;

    .trading-token-footer-container {
      height: fit-content;
    }
  }
}
