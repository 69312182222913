.progress-bar {
  position: relative;
  width: 100%;
  height: 12px;
  border-radius: 100px;

  .progress-bar-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: #222;
  }

  .progress-bar-value {
    position: absolute;
    width: 0;
    height: 100%;
    border-radius: 100px;
    background-color: white;

    &.yellow {
      background-color: #f5c005; // yellow'
    }
  }
}
