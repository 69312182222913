.tiktok-tap-game {
  // note that pointer events need to be active in this container
  // so we can tap-tap when tapgame starts

  // root container needs to be always visible
  // to avoid issues while swiping on carousel
  // opacity is changed dynamically from js syle
  transition: opacity 350ms ease-out;

  // note: this should not be necessary since is implemented in every div
  // but explicitly adding it just to be sure
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tap-area {
    // inner tap-area element is what is shown or hidden
    visibility: hidden;
    &.visible {
      visibility: visible;
    }

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .tiktok-tap-game-ui-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .tiktok-tap-game-ui {
      pointer-events: none;
      width: 100%;

      // setup fullscreen safe-area
      padding-top: calc(var(--safe-top) + 0px);
    }
  }

  // ============================================================================================
  // Top UI (Timer, Points)

  .top-ui {
    pointer-events: none;
    text-align: center;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;

    // setup fullscreen safe-area
    margin-top: 25%;

    &.game-timer {
      top: 10%;
      left: 10%;
    }

    // necessary to hide stuff instantly
    &.invisible {
      visibility: hidden;
    }

    .points-circle {
      display: flex;
      justify-content: center;
      align-items: center;

      .token-icon {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }

      .points-value {
        font-family: PPPangramSansRounded-Bold;
        font-size: 48px;
        font-weight: bold;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }
    }

    .points-label {
      pointer-events: none;
      font-family: PPPangramSansRounded-Bold;
      font-size: 14px;
      display: block;
      color: #dedede;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      margin-top: 2px;
    }

    .point-increments {
      pointer-events: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .increment {
        font-size: 18px;
        margin-bottom: 5px;
        animation: fadeInUp 0.5s forwards;
        opacity: 0;

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 0.1}s;
          }
        }
      }
    }

    .timer-container {
      pointer-events: none;
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-bottom: 16px;

      img {
        position: absolute;
      }

      .timer-circle {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 56px;
        height: 56px;

        .progress {
          width: 38px;
          height: 38px;

          transform: rotate(-90deg);

          .pie .pie-chart {
            fill: none;
            stroke: white;
            stroke-width: 32;

            // works, but creates confusion on some users, so let's not use it for now
            // transition: all 0.5s ease-in-out;
          }
        }
      }

      .timer-time {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 140px;
        height: 48px;

        margin-left: -56px;
        padding-top: 2px;

        .label {
          font-family: PPPangramSansRounded-Bold;
          font-size: 18px;
          margin-left: 48px;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); // Added drop shadow
        }
      }
    }
  }

  // ============================================================================================
  // Countdown 3 2 1

  .countdown-container {
    pointer-events: none;

    // setup fullscreen safe-area
    position: absolute;
    top: calc(50% - 60px);

    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: blink 1s infinite;

    .countdown {
      font-family: PPPangramSansRounded-Bold;
      font-size: 148px;
      font-weight: bold;

      color: white;
      opacity: 0.35;
    }
  }

  // ============================================================================================
  // Finger tooltip

  .tap-to-earn {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    animation: blink 1.5s infinite;

    // setup fullscreen safe-area
    pointer-events: none;
    position: absolute;
    top: 57%;

    .tap-to-earn-content {
      // necessary to hide stuff instantly
      &.invisible {
        visibility: hidden;
      }

      img {
        height: 146px;
        object-fit: contain;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5)); // Added drop shadow
      }
    }
  }

  // =======================================================
  // FX (ripples, flying points)

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  #coin-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .point-up {
    position: absolute;
    color: white;
    font-size: 24px;
    font-weight: bold;
    opacity: 0;
    animation: pointUpAnimation 0.4s ease-out;
    pointer-events: none;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); // Added drop shadow
  }

  #ripple-templates-tiktok {
    display: none;
  }

  .ripple-circles-tiktok {
    pointer-events: none;
    z-index: 0;

    position: absolute;
    width: 100%;
    height: 100%;

    width: 300px;
    height: 300px;

    > div {
      animation: circlesGrowAndFade 0.5s 1 ease-out;

      background-color: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    .ripple-circle-1 {
      animation-delay: 0.1s;
    }
    .ripple-circle-2 {
      animation-delay: 0.2s;
    }
    .ripple-circle-3 {
      animation-delay: 0.3s;
    }
  }
}

@keyframes pointUpAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

// ============================================================================================
