.modal-drawer.drawerInfoDex {
  .modal-drawer-box {
    padding-top: 24px;
    padding-bottom: 32px;
  }
}

.drawer-info-dex {
  width: 100%;
  // height: 100%; // -> important: this needs to be unset
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .drawer-info-dex-header {
    width: 100%;
    padding-bottom: 28px;

    .drawer-info-dex-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 18px;
      color: white;
      text-align: left;
      width: 100%;
    }
  }

  .drawer-info-dex-steps {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;

    .drawer-info-dex-step {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 6px;

      .drawer-info-dex-step-title {
        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;
        color: white;
        text-align: left;
      }
      .drawer-info-dex-step-subtitle {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 13px;
        color: #777;
        text-align: left;
        line-height: 1.5;
      }
    }
  }
}
