@font-face {
  font-family: PPPangramSansRounded-Bold;
  src: url('../assets/font/PPPangramSansRounded-Bold.woff');
}

@font-face {
  font-family: PPPangramSansRounded;
  src: url('../assets/font/PPPangramSansRounded-CompactRegular.woff');
}

@font-face {
  font-family: PPPangramSansRounded-Semibold;
  src: url('../assets/font/PPPangramSansRounded-Semibold.woff');
}

@font-face {
  font-family: PPPangramSansRounded-Regular;
  src: url('../assets/font/PPPangramSansRounded-Regular.woff');
}

@font-face {
  font-family: PPPangramSansRounded-Medium;
  src: url('../assets/font/PPPangramSansRounded-Medium.woff');
}

html,
body {
  margin: 0;
  padding: 0;
  background: black;

  display: flex;
  justify-content: center;
  color: white;
  font-family: PPPangramSansRounded-Medium;
  width: 100%;
  // height: 100%;
  height: 100svh;

  overflow: hidden;
}

body[dir='rtl'] {
  direction: rtl;
  text-align: right;
}

:root {
  // in fullscreen, this is the real value,
  // but when not on fullscreen, this will incorrectly push content down 34px (bottom inset),
  // so lets not do it for now, since our frontend already deals with bottom safe area in a different way
  // --safe-top: calc(
  //   var(--tg-safe-area-inset-top) + var(--tg-safe-area-inset-bottom) + 0px
  // );

  // instead, lets hack a good value for pushing content down only on fullscreen
  --safe-top: calc(
    var(--tg-safe-area-inset-top) + (var(--tg-safe-area-inset-top) * 0.85) + 0px
  );

  --safe-height: calc(
    100svh - var(--tg-safe-area-inset-top) - var(--tg-safe-area-inset-bottom)
  );
}

#root {
  // note: there was a reason for this but doesnt seem to apply anymore
  // max-width: 414px;
  margin: auto;
  width: 100%;
  height: 100%;
}

.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;

  height: 100svh;
  margin-top: 0;
}

.qr-code {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  .label {
    padding-top: 24px;
    font-family: PPPangramSansRounded-Medium;
    font-size: 16px;
  }
}

div,
img {
  // border: 1px solid green;
  box-sizing: border-box;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-callout: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-highlight: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

div::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

a {
  text-decoration: none;
}

.hide {
  display: none !important;
}

#__vconsole {
  position: absolute;
  .vc-switch {
    right: 10px !important;
    bottom: 10px !important;
  }
}

// used on different screens while loading content
.loading-label {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: white;

  &.fixed {
    position: absolute;
    top: 50%;
  }
}

.invisible {
  visibility: hidden !important;
}

.highlight {
  // border: 1px solid #ffce06;
  color: #ffce06;
  // z-index: 9999;
}

.inactive {
  pointer-events: none !important;
}

.mystery {
  pointer-events: none !important;
  opacity: 0.35;
}

.disabled {
  opacity: 0.6;
  pointer-events: none !important;
}

.removed {
  display: none !important;
}

.squashed {
  height: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.underline {
  text-decoration: underline;
}

// ------------------------------------------------------------------------------------------------

// apply shadow to svg by adding a css classname

.svg-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
}

.svg-shadow-light {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

// ------------------------------------------------------------------------------------------------

//  This is the cheats/debug overlay

.dg.ac {
  // setup fullscreen safe-area
  top: calc(var(--safe-top) + 4px) !important;

  .main {
    .close-button {
      background-color: darkslateblue !important;
    }
    .close-bottom {
      background-color: darkslateblue !important;
    }
  }
}

// ------------------------------------------------------------------------------------------------

// This is the react fullscreen overlay that shows when there is an error

#webpack-dev-server-client-overlay {
  top: calc(var(--safe-top) + 4px) !important;
}

// ------------------------------------------------------------------------------------------------
