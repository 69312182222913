.page.tiktokSearch {
  overflow-y: hidden;
  overflow-y: auto;

  .tiktok-search-container {
    padding-top: 20px;
    width: 100%;

    .trading-tabs-container {
      padding-top: 20px;

      .items-area {
        overflow-y: scroll;
        height: calc(100svh - 180px);
        padding-bottom: 16px;
        padding-top: 4px;

        // .trading-items-list {}
      }
    }
  }
}
