.page.trading-create {
  // necessary for page to scrolling
  display: block;

  // dealing with telegram safe area
  padding: 16px;
  &.telegram {
    padding-top: calc(var(--safe-top) + 8px);
  }

  .feed-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.trading-create-layout {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 18px; // fit things better than 20px

  .trading-create-header {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .trading-create-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 20px;
      color: white;
    }

    // user image - camera
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 16px;

    .trading-create-image-container {
      position: relative;

      width: 100%;
      height: 200px;

      background-color: #232323;
      border-radius: 16px;
      border: unset;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .trading-create-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 128px;
        height: 200px;

        background-color: transparent;
        border-radius: 16px;

        &.uploaded {
          background-image: url('../../../assets/create/card_front.svg');
        }

        img {
          width: unset;
          height: unset;
          object-fit: cover;
        }

        .container {
          .trading-create-trash {
            position: absolute;
            top: 8px;
            right: 8px;

            img {
              width: 40px;
              height: 40px;
            }
          }

          .img-container {
            .img-box {
              .btn-add {
                .image-container {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  overflow: hidden;

                  width: 118px;
                  height: 172px;
                  margin-top: -16px;

                  border-radius: 8px; // necessary in desktop

                  img {
                    width: unset;
                    height: unset;
                    object-position: 0 0;
                    border-radius: 8px; // necessary in mobile
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .button-link-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;

    img {
      width: 20px;
      height: 20px;

      &.up {
        transform: scaleY(1) translateY(1px);
      }

      &.down {
        transform: scaleY(-1) translateY(0px);
      }
    }

    .label {
      font-family: PPPangramSansRounded-Bold;
      font-size: 16px;
      color: #04a0f0;
    }
  }

  .trading-create-footer {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    // dealing with bottom button margin
    padding-bottom: 16px;
    &.expanded {
      padding-top: 8px;
      &.telegram {
        padding-bottom: 32px;
      }
    }

    .trading-create-footer-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      font-family: PPPangramSansRounded-Bold;
      font-size: 13px;
      color: white;

      img {
        width: 14px;
        height: 14px;
        margin-top: -2px;
      }
    }
  }

  .button-continue {
    height: 48px;

    &.editlinks {
      margin-bottom: 16px;
    }
  }
}
