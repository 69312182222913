.meme-image-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .meme-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .badge {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 33%;
    height: 33%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
