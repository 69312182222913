$blur: 11px;

.modal-drawer.drawerFtueShare {
  // full-modal content needs to be centered
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: black; //  #121212 !important;
  }

  .modal-drawer-box {
    background-color: unset; // rgba(255, 0, 0, 0.5);

    // margin-top: var(--safe-top);

    // full-modal content needs to be centered
    position: relative;
    padding: 0;
    padding-top: 5svh; // 100px;
    padding-bottom: 0svh !important;

    border: none;
    border-radius: 0;

    height: 100%;
    width: 100%;

    animation: unset;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
}

.drawer-ftue-share {
  // background-color: orange;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  // padding-top: 48px;

  max-height: 600px; // calc(100% - 100px);

  // ========================================================================
  // Error State

  .drawer-ftue-share-error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .drawer-ftue-share-error-title {
      font-size: 16px;
    }
    .drawer-ftue-share-error-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .drawer-ftue-share-error-message {
        font-size: 10px;
        opacity: 0.5;
        text-align: left;
      }
    }
    .drawer-ftue-share-error-button {
      height: 52px;
      width: 200px;
      margin-top: 8px;
    }
  }

  // ========================================================================
  // Loading State

  .drawer-ftue-share-loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 32px;

    .drawer-ftue-share-loading {
      width: 100%;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 32px;

      padding: 32px;

      .drawer-ftue-share-checking {
        font-family: PPPangramSansRounded-Bold;
        font-size: 24px;
        color: white;
        line-height: 1.2;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      }

      .drawer-ftue-share-loading-bars {
        width: 80%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .progress-bar-container-v {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          gap: 8px;

          .progress-bar-text {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 13px;
            color: white;
            line-height: 1.2;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            opacity: 0.5;
          }

          .progress-bar-container-h {
            width: 100%;
            height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .progress-bar {
              width: 100%;

              .progress-bar-bg {
                background-color: #222;
              }
              .progress-bar-value {
                &.yellow {
                  background-color: #f5c005; // yellow'
                }
              }
            }

            .progress-bar-icon {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 8px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .progress-bar {
    position: relative;
    width: 200%;
    height: 12px;
    border-radius: 100px;

    .progress-bar-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .progress-bar-value {
      position: absolute;
      width: 50%;
      height: 100%;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  // ========================================================================
  // Top - Header

  .drawer-ftue-share-header {
    // background-color: magenta;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .drawer-ftue-share-header-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 48px;
      color: white;
    }
  }

  // ========================================================================
  // Centered Content

  .drawer-ftue-share-content {
    // background-color: blue;

    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &.image {
      margin-top: -16px;
    }

    // =========================================
    // meme-info (rounded meme image and value label)

    .drawer-ftue-share-meme-info {
      // margin-top: 8px;

      .value {
        font-family: PPPangramSansRounded-Bold;
        font-size: 48px;
        color: white;
      }
      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .currency {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 16px;
        }
      }
    }

    // =========================================
    // swap-info

    .drawer-ftue-share-swap-info {
      // background-color: red;
      // width: calc(100% - 32px);

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; // space-evenly;
      gap: 16px;

      .swap-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        .swap-label {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 13px;
          color: #777;
        }
        .swap-value {
          font-family: PPPangramSansRounded-Bold;
          font-size: 18px;
        }
      }
    }
    // =========================================
  }
}

// ========================================================================
// Bottom - Buttons

.drawer-ftue-share-buttons {
  // background-color: magenta;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .drawer-ftue-share-button {
    max-width: 200px;
    height: 56px;

    margin-top: 1svh;
  }

  // ========================================================================
}
