// =============================================
// Profile item

.item-profile--compact {
  height: 48px !important;
  // padding: 8px !important;
}

.item-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 72px;
  border-radius: 16px;
  background-color: #1a1a1a;

  padding: 12px;

  .item-profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 12px;
    width: 100%;
    height: 48px;

    .item-profile-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: calc(100% - 48px);

      // left

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .name-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-Medium;
          font-size: 14px;
          color: white;
        }

        .owned-container {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 4px;

          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: #777;
        }
      }

      // right

      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .price-container {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          justify-content: end;

          font-family: PPPangramSansRounded-Medium;
          font-size: 14px;
        }

        .change-container {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: start;
          justify-content: end;

          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: #777;

          &.green {
            color: #09d0a8;
          }
          &.red {
            color: #d00948;
          }
        }

        .button-claim {
          width: 64px;
          height: 32px;
          font-size: 12px;
          background-color: #09d0a8; // green
        }

        .progress-points {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 32px;
        }

        .pending-container {
          display: flex;
          align-items: center;

          .pending-spinner {
            position: relative;
            width: 20px;
            height: 20px;
            margin-left: 8px;
            border: 3px solid #666; // Light gray border
            border-top: 3px solid #09d0a8; // Green color for the spinner
            border-radius: 50%;
            opacity: 1;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}
