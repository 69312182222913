$blur: 11px;

.modal-drawer.drawerPartnerReferral {
  .modal-drawer-backdrop {
    -webkit-backdrop-filter: blur($blur) !important;
    backdrop-filter: blur($blur) !important;
    background-color: rgba(0, 0, 0, 1) !important;
  }

  .modal-drawer-box {
    background-color: unset;
    position: absolute;
    bottom: 0;

    border: none;
    border-radius: 0;

    width: 100%;
    height: 100%;
    max-height: calc(100svh - (var(--safe-top) + 0px));

    padding: 16px;

    animation: unset;
  }
}

// ========================================================================

.drawer-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  .drawer-partner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .drawer-partner-image {
      margin-bottom: 12px;
    }

    .drawer-partner-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &.welcome {
        gap: 0px;
      }
    }

    .drawer-partner-label {
      font-family: PPPangramSansRounded-Bold;
      font-size: 24px;
      color: white;
      line-height: 1.2;
      // text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

      &.s16 {
        font-size: 16px;
      }
      &.s20 {
        font-size: 20px;
      }
      &.s24 {
        font-size: 24px;
      }
      &.s48 {
        font-size: 48px;
      }

      &.yellow {
        color: #f5c005; // yellow'
      }

      &.grey {
        font-family: PPPangramSansRounded-Medium;
        color: #777;
      }
    }

    .drawer-partner-reward {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      img {
        width: 40px;
        height: 40px;
        margin-top: -1px;
      }
    }

    .drawer-partner-button {
      width: 170px;
      height: 48px;
    }
  }
}

// ========================================================================
