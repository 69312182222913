// ==========================================
// General button behaviour

.btn {
  pointer-events: auto;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  transition: transform 0.1s ease-in-out;
  &:active {
    transform: scale(0.95);
  }

  &.disabled,
  &[disabled],
  &:disabled {
    opacity: 0.6;
    cursor: initial;
    pointer-events: none;
  }
}

// ==========================================
// Black and White buttons

.btn-normal,
.btn-inverse {
  pointer-events: auto;
  position: relative;

  // inverse by default
  background-color: #111;
  color: #fff;

  border: unset;
  background-color: #111;
  color: #fff;

  border-radius: 40px;

  font-family: PPPangramSansRounded-Bold;
  font-size: 16px;
  user-select: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px; // 64px;
  margin: 0;
}

.btn-normal {
  background-color: #fff;
  color: #111;
  border: 1px solid #111;
  width: 100%;

  &.share {
    width: 80px;
    img {
      width: 40%;
    }
  }
}

.btn-enormus {
  // height: 80px;
  margin-top: 8px;
}

.btn-inverse {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
}

.footer-buttons {
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  gap: 8px;

  &.vertical {
    flex-direction: column;
    gap: 12px;
  }
}

// ==========================================
// Blue buttons

.btn-clicker {
  position: relative;

  width: 33%;
  height: 13svh;
  min-height: 64px;
  max-height: 76px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: PPPangramSansRounded-Bold;
  font-size: 2vmin; // 28px;
  line-height: 4vmin; // 24px;
  color: #ffffff;

  border-image-source: url('../assets/buttons/btn_clicker_dark.svg');
  border-image-width: 90px; // 22px;
  border-image-slice: 45 45 45 45 fill; /* top | right | bottom | left | middle */

  &.highlight {
    border-image-source: url('../assets/buttons/btn_clicker_yellow.svg');
    color: #ffce06;
  }
}

.btn-clicker.join-team {
  width: 100%;
  height: 100%;
  border-image-source: url('../assets/buttons/btn_clicker_light.svg');

  &.highlight {
    border-image-source: url('../assets/buttons/btn_clicker_yellow.svg');
    color: #ffce06;
  }
}

.btn-clicker.my-team {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  border-image-source: url('../assets/buttons/btn_clicker_transparent.svg');

  &.highlight {
    border-image-source: url('../assets/buttons/btn_clicker_yellow.svg');
  }
}

.btn-clicker.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;

  width: 80px;

  border-image-source: unset;
  border-image-width: unset;
  border-image-slice: unset;

  .label {
    font-family: PPPangramSansRounded-SemiBold;
    font-size: 3.5vmin; // 28px;
    line-height: 4vmin; // 24px;
  }

  img {
    height: 9vmin; // 24px;
  }

  &.booster {
    flex-direction: row;
    gap: 5px;

    width: calc(33% - 8px);

    height: 44px;
    min-height: 44px;
    max-height: 44px;

    margin-top: 0; // 0.25svh;

    border-image-source: url('../assets/buttons/btn_clicker_dark.svg');
    border-image-width: 90px; // 22px;
    border-image-slice: 45 45 45 45 fill; /* top | right | bottom | left | middle */

    img {
      height: 6vmin; // 24px;
    }
  }
}
