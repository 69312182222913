// =============================================
// chart types

.trading-chart {
  // background-color: green;
  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}

.trading-mini-chart {
  pointer-events: none;
  overflow-y: auto;

  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}

.portfolio-chart {
  overflow-y: auto;

  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
}
