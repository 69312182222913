.modal-drawer.criticalError {
  // background-color: red;

  .modal-drawer-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    background-color: black;
    padding: 24px;
    padding-top: 40px;

    border: 1px solid #444;
    border-bottom: unset;
    border-radius: 32px 32px 0 0;

    .modal-drawer-button-close {
      display: none;
    }
  }

  .auth-error {
    // background-color: blue;
    .drawer-layout {
      .drawer-header {
        padding-bottom: 8px;

        .drawer-header-title {
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 22px;
          margin-top: 4px;
        }

        .drawer-header-subtitle {
          font-family: PPPangramSansRounded-Medium;
          font-size: 15px;
          color: #999;
          line-height: 1.5;

          padding-bottom: 16px;
        }
      }
    }

    .footer-buttons {
      align-items: center;
      justify-content: center;
      .button-critical-error {
        // background-color: red;
        max-width: 150px;
      }
    }
  }
}
