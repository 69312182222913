// ==========================================================================================
// Feed Item (slide)

$footer-height-desktop: 72px;
$footer-height-mobile: 84px;

.feed-meme {
  position: relative;
  overflow: hidden;

  .tiktok-tap-game {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  // feed slides need to be fullscreen height minus the height of the footer
  height: calc(100svh - #{$footer-height-desktop});
  &.telegram-ios {
    height: calc(100svh - #{$footer-height-mobile});
  }

  font-family: PPPangramSansRounded-Bold;
  font-size: 20px;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 0;

  .feed-meme-image {
    transition: transform 200ms ease-in-out;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .feed-meme-media-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .feed-meme-video {
    transition: transform 200ms ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feed-ad-title-description {
    position: absolute;
    text-align: left;
    bottom: 12px;
    left: 3%;
    width: 94%;
    padding: 4px;
    z-index: 2;
  }

  .feed-buttons {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 8px;
    margin-top: 8px;
    height: 56px;

    z-index: 3;

    .feed-ad-button {
      padding: 12px;
      font-size: 15px;
      height: 56px;

      &.disabled {
        opacity: 0.6;
      }
    }

    .feed-ad-btn-copy {
      border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
      border-image-width: 90px;
      border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

      position: relative;

      width: 90px;
      height: 56px;

      .feed-ad-copy-success {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        border-radius: 20px;
        background-color: black;
        border: 1px solid #222;

        position: absolute;
        top: -44px;
        left: -32px; // otherwise disappears under page overflow horizontal padding...

        padding: 6px;
        padding-right: 10px;
        box-sizing: border-box;

        .feed-ad-copy-success-icon {
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .feed-ad-copy-success-text {
          font-family: PPPangramSansRounded-Medium;
          font-size: 13px;
          color: white;
          padding-top: 2px;
          box-sizing: border-box;
        }
      }
    }
  }

  .feed-ad-countdown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px; /* Adjust the height as needed */
    background-color: rgba(
      0,
      0,
      0,
      0.2
    ); /* Background color of the progress bar container */
    overflow: hidden;
  }

  .feed-ad-progress-bar {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; /* Color of the progress bar */
    transition: width 0.1s linear;
  }

  .feed-ad-sponsored {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 900px;

    // @note: gradient not working as it removes the border radius!
    // border-image: linear-gradient(
    //   to right,
    //   rgba(255, 255, 255, 0.2) 0%,
    //   rgba(255, 255, 255, 0.6) 55%,
    //   rgba(255, 255, 255, 1) 80%,
    //   rgba(255, 255, 255, 0.2) 100%
    // );
    // border-image-slice: 1;

    width: 112px;
    height: 32px;
    left: 16px;
    top: 606px;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    font-family: PPPangramSansRounded-Bold;
    font-size: 12px;
    color: #fff;

    height: 32px;
    margin-bottom: 12px;
    padding: 16px 19px;

    z-index: 1;
  }

  .feed-ad-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  .feed-ad-title {
    font-family: PPPangramSansRounded-Bold;
    font-size: 18px;
    text-align: left;
    color: white;
    margin-bottom: 8px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
  }

  .feed-ad-description {
    font-family: PPPangramSansRounded-Regular;
    font-size: 12px;
    text-align: left;
    color: white;
    opacity: 60%;
    line-height: 20px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
  }

  // for testing purposes
  .debug-label {
    pointer-events: none;
    z-index: 9999;
    position: absolute;
    left: 100px;
    top: 100px;
    font-family: PPPangramSansRounded-Bold;
    font-size: 14px;
    color: red;

    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  // new bottom gradient
  .feed-meme-bottom-gradient {
    pointer-events: none;
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 440px; // 198px;
    opacity: 1; // 0.8;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

// ==========================================================================================
