// =============================================
// tabs - solid rounded style

.tabs .btn {
  position: relative;
}

.tabs-header-rounded {
  width: 100%;
  height: 40px;

  background-color: #222;
  border-radius: 6px;

  background-color: unset;

  border: 1px solid #232323;

  &.extra-rounded {
    border-radius: 90px;
  }

  padding: 0px;
  box-sizing: border-box;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0;

    width: 100%;
    height: 100%;

    box-sizing: border-box;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      border-radius: 6px;
      padding: 4px;

      &.extra-rounded {
        border-radius: 90px;
      }

      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;

      &.selected {
        background-color: #232323;
      }

      .tab-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;

        img {
          height: 100%;
          margin-top: -1px;
        }

        .tab-icon {
          width: 16px;
          height: 16px;
          overflow: hidden;
          border-radius: 90px;
          margin-top: -2px;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// =============================================
// tabs - underline style

.tabs-header-underline {
  width: 100%;
  height: 40px;

  padding: 4px 0;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 0px; // 4px;

    width: 100%;
    height: 100%;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      border-radius: unset;
      padding: 8px; // 16px;

      font-family: PPPangramSansRounded-Semibold;
      font-size: 12px;

      border-bottom: 1px solid #777;

      &.selected {
        border-bottom: 1px solid #fff;
      }
    }
  }
}

.tabs-header-large {
  width: 100%;
  height: 40px;

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    width: 100%;
    height: 100%;

    margin-top: 10px;
    margin-bottom: 25px;

    padding: 0;
    box-sizing: border-box;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;

      border-radius: 0;
      padding: 0;

      font-family: PPPangramSansRounded-Bold;
      font-size: 18px;

      &.selected {
        background-color: unset;
        color: white;
      }

      &:not(.selected) {
        color: #777;
      }
    }
  }
}
