// ============================================
// Friends Header

.friends-header {
  padding-top: 16px;

  .friends-header-title {
    font-family: PPPangramSansRounded-Semibold;
    font-size: 32px;
    padding-top: 0;
    margin-top: 8px;
  }
  .friends-header-subtitle {
    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    color: #777;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}

.page.friends {
  // background-color: red;
  padding-bottom: 120px;
}

// ============================================
// Friends CTA

.friends-cta {
  background-color: #222;
  border-radius: 12px;
  padding: 16px;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 12px;

  .separator {
    width: 100%;
    height: 1px;
    background-color: #444;
    margin: 10px 0;
  }

  .friend-cta-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 12px;

    width: 100%;

    .icon-1 {
      padding-top: 3px;
      width: 40px;
      height: 40px;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .info-1 {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 2px;
      width: 100%;
      text-align: left;

      .title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 16px;
        text-align: left;
      }

      .info-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 8px;
        width: 100%;
        text-align: left;

        .icon-2 {
          width: 20px;
          height: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }

        .info-3 {
          font-family: PPPangramSansRounded-Medium;
          font-size: 16px;
          width: 100%;
          text-align: left;
          padding-top: 3px;
          .yellow {
            color: yellow;
          }
        }
      }
    }
  }

  .opendetails {
    padding-top: 12px;
    font-family: PPPangramSansRounded-Bold;
    font-size: 18px;
  }
}

// ============================================
// Friend Card

.team-list.friends {
  gap: 24px;
  padding-top: 0;

  .card.friend {
    .card-left {
      .image {
        width: 42px;
        height: 42px;
        img {
          width: 42px;
          height: 42px;
        }
      }
    }

    .info {
      width: calc(100svw - 185px);
    }
  }
}

.friend-card-special-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;

  .friend-rank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 8px;

    .friend-rank-icon {
      display: flex;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .friend-rank-label {
      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
    }
  }

  .separator {
    width: 1px;
    height: 20px;
    background-color: #444;
  }

  .friend-bonus {
    font-family: PPPangramSansRounded-Bold;
    font-size: 16px;
    color: #f5c005;
  }
}
