// =============================================
// trading onchainToken item

.item-trading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 88px;
  border-radius: 16px;
  background-color: #1a1a1a;

  padding: 12px;

  .item-trading-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    gap: 8px;
    width: 100%;
    height: 64px;
  }
}

.item-trading-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 8px;
  width: calc(100% - 78px);

  // left

  .left {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 6px;
    width: calc(100% - 34px);

    .name-container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 4px;

      font-family: PPPangramSansRounded-Medium;
      font-size: 12px;
      color: #8d99a8;
    }

    .owned-container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 4px;

      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
      color: #09d0a8; // green

      .holders {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin: 0;
        padding: 0;

        font-family: PPPangramSansRounded-Medium;
        font-size: 12px;
        color: white;

        margin-left: 2px;

        .icon {
          margin-top: -2px;
        }

        .label {
          margin-top: 1px;
        }
      }
    }

    .creator-container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      gap: 4px;

      font-family: PPPangramSansRounded-Medium;
      font-size: 12px;
      color: #a1d2f8;

      .creator-label {
        &.name {
          width: calc(100% - 36px); // otherwise creator icon will get deformed
          text-align: left;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      img {
        width: 14px;
        height: 14px;
        border-radius: 90px;
        margin-top: 0px;
      }
    }
  }

  // right

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    .price-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      justify-content: end;

      font-family: PPPangramSansRounded-Medium;
      font-size: 14px;
    }

    .change-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: start;
      justify-content: end;

      font-family: PPPangramSansRounded-Bold;
      font-size: 12px;
      color: #777;

      &.green {
        color: #09d0a8;
      }
      &.red {
        color: #d00948;
      }
    }

    .button-claim {
      width: 32px;
      height: 32px;

      img {
        width: 100%;
      }
    }

    .progress-points {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }
  }
}

// =============================================
// trading offchainToken item

.trading-token-item {
  width: 100%;
  justify-content: start !important;

  padding: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

  .trading-token-item-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 80px;

    .trading-token-item-image {
      width: 80px;
      height: 80px;
      border-radius: 12px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    .trading-token-item-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 4px;

      .trading-token-item-top {
        .trading-token-item-title {
          font-family: PPPangramSansRounded-Bold;
          font-size: 14px;
          text-align: left;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .trading-token-item-market-cap {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          justify-content: start;
          font-family: PPPangramSansRounded-Semibold;
          font-size: 12px;
          text-align: left;

          .trading-token-item-market-cap-label {
            color: #777;
            text-align: left;
            padding-top: 2px;
          }
          .trading-token-item-market-cap-value {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;

            .label {
              margin-top: 3px;
            }

            .icon {
              height: 14px;
              img {
                height: 100%;
              }
            }
          }

          .trading-token-item-market-cap-percent {
            font-size: 10px;
            color: #09d0a8;
            margin-top: 3px;
          }
        }
      }

      .trading-token-item-bottom {
        .trading-token-item-author {
          display: flex;
          flex-direction: row;
          gap: 4px;
          align-items: center;
          margin-bottom: 4px;

          .trading-token-item-author-tappable {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
          }

          .trading-token-item-author-label {
            font-family: PPPangramSansRounded-Medium;
            font-size: 12px;
            color: #999;
            text-align: left;
          }

          .trading-token-item-author-image {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            img {
              width: 20px;
              height: 20px;
              border-radius: 100%;
            }
          }
          .trading-token-item-author-name {
            font-family: PPPangramSansRounded-Semibold;
            font-size: 12px;
            text-align: left;
          }
        }
      }
    }
  }
}
