// =================================================================
// Swipe Tutorial Overlay

.tiktok-swipe-tutorial-overlay {
  pointer-events: none;
  z-index: 11;
  // background-color: rgba(255, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100svh;

  position: absolute;
  top: 0;
  left: 0;

  // -------------------------------
  // overlay fade-in-out transitions
  transition: all 0.5s ease-in-out;
  opacity: 0;
  &.in {
    opacity: 1;
  }
  &.out {
    opacity: 0;
  }
  // -------------------------------

  .swipe-tut-content {
    // background-color: rgba(0, 255, 0, 0.5);
    position: relative;
    width: 100%;
    height: 220px;

    margin-top: 20%;
    &.telegram {
      margin-top: calc(20% + var(--safe-top) + 0px); // 12%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    transform: scale(0.85);

    .swipe-tut-hand {
      // background-color: rgba(255, 0, 255, 0.5);
      position: relative;
      width: 100px;
      height: 100%;

      .hand-and-dot {
        position: relative;
        animation: handUpDown 1.5s infinite alternate ease-in-out;

        .dot-graph {
          position: absolute;
          left: 32px;
          top: 0;
        }

        .hand-graph {
          position: absolute;
          left: 60px;
          top: 20px;
        }
      }

      .line-graph {
        position: absolute;
        left: 50px;
        top: 45px;

        transform-origin: bottom;

        animation: lineUpDown 1.5s 0s infinite alternate ease-in-out;
      }
    }

    .swipe-tut-message {
      font-family: PPPangramSansRounded-SemiBold;
      font-size: 20px;
      color: white;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

// =================================================================
// Hand and Line animations

@keyframes handUpDown {
  from {
    transform: translateY(111px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes lineUpDown {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

// =================================================================
