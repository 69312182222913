.modal-drawer.drawerTradingTransactionConfirm {
  .modal-drawer-box {
    padding-top: 27px;

    .drawer-trading-transaction-confirm {
      .drawer-trading-transaction-confirm-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;

        .title {
          font-family: PPPangramSansRounded-Bold;
          font-size: 20px;
        }

        .rows {
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;

          .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 44px;
            border-bottom: 0.5px solid #777;

            .left {
              font-family: PPPangramSansRounded-Medium;
              font-size: 14px;
              color: #777;
            }

            .right {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 6px;

              .icon {
                width: 20px;
                height: 20px;
                border-radius: 90px;
                margin-top: -2px;
              }
              .value {
                font-family: PPPangramSansRounded-SemiBold;
                font-size: 14px;
              }
            }
          }
        }

        .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 12px;

          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}

// -------------------------

.trading-token-footer-fee-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: PPPangramSansRounded-Medium;
  font-size: 12px;
  color: #777;

  .coin {
    width: 20px;
    height: 20px;
    img {
      width: 100%;
    }
  }

  .percent {
    font-family: PPPangramSansRounded-Semibold;
    color: white;
  }

  .value {
    font-family: PPPangramSansRounded-Semibold;
    color: white;
  }
}

.button-buy {
  width: 100%;
  height: 50px !important; // 52px;

  // button

  .content-buy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;

    font-family: PPPangramSansRounded-Bold;
    font-size: 14px;

    color: black;

    .value {
      white-space: normal;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-top: -1px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 90px;
      }
    }
  }
}

// -------------------------
