// ==========================================================================================
// Feed Item - Bottom UI

.feed-meme-ui-bottom {
  pointer-events: none;

  z-index: 3;
  position: absolute;

  width: 100%;
  height: 280px; // 440px;
  bottom: 0;

  transition: opacity 350ms ease-out;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  .feed-meme-ui-content {
    // background-color: red;
    pointer-events: none;
    position: relative;

    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    gap: 12px;

    width: 100%;
    padding: 16px;

    // ===============================

    // whole tappable bottom ui area,
    // which will take us to meme-details

    .bottom-ui-container {
      // background-color: magenta;
      width: 100%;
      pointer-events: auto;
      flex-direction: column;
      align-items: start;
      justify-content: end;
      gap: 12px;
    }

    // ===============================

    // whole meme info on the left (image and text)

    .bottom-ui-item {
      // background-color: pink;
      pointer-events: auto;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 8px;
      flex-shrink: 0;

      // ----------------------

      // meme image icon: is driven by MemeImage component

      // ----------------------

      // meme text info (ticker + marketcap)

      .text-area {
        // background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 4px;

        .label {
          // background-color: black;
          font-family: PPPangramSansRounded-SemiBold;
          font-size: 14px;
          color: white;
          width: fit-content;

          &.tickerName {
            font-family: PPPangramSansRounded-Bold;
            font-size: 14px;
            color: white;
          }

          &.marketCap {
            font-family: PPPangramSansRounded-Medium;
            font-size: 14px;
            color: white;
          }
        }
      }
    }
  }

  .progress-bar-area {
    // background-color: red;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .progress-bar-left {
      // background-color: green;
      padding-top: 4px;
      width: fit-content;
      width: 100%;
    }
    .progress-bar-right {
      // background-color: blue;
      width: fit-content;
      text-wrap: nowrap;
      margin-right: 2px;

      font-family: PPPangramSansRounded-Medium;
      font-size: 12px;
      text-align: right;
      color: white;
    }
  }
}

// ==========================================================================================
// Buy/Sell Footer (part of bottom-ui-content)

.buysell-footer {
  z-index: 5;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  transition: opacity 350ms ease-out;

  .buysell-footer-buttons {
    border-image-source: url('../../../../assets/buttons/btn_profile_dark.svg') !important;
    border-image-width: 90px;
    border-image-slice: 28 28 28 28 fill !important; /* top | right | bottom | left | middle */

    width: 100%;
    height: 50px;

    margin-top: 2px;
    padding: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;

    .buysell-footer-button {
      width: 50%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      img {
        width: 22px;
        height: 22px;
      }
      .label {
        font-family: PPPangramSansRounded-Bold;
        font-size: 16px;
        color: white;
      }
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
}
