.modal-drawer.drawerProfileSeasonInfo {
  .modal-drawer-box {
    padding: 16px;
    padding-bottom: 24px;
  }

  .profile-season-wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    padding: 32px;
    .wallet-label {
      font-family: PPPangramSansRounded-Medium;
      font-size: 15px;
      color: #777;
    }
  }

  .drawer-layout.profile-season-info {
    .drawer-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 24px;

      .profile-season-info-title {
        font-family: PPPangramSansRounded-SemiBold;
        font-size: 22px;
        margin-top: -18px;
      }

      .profile-season-info-rows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        width: 70%;

        padding-bottom: 16px;

        .profile-season-info-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          width: 100%;

          .left {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            color: #777;
          }
          .right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 4px;

            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            color: white;

            img {
              width: 14px;
              height: 14px;
              margin-top: -1px;
            }
          }
        }
      }
    }
  }
}
