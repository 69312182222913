// =================================================
// trading footer. with buy/sell tabs, input and button

.tabs-container {
  padding-right: 40px; // Adjust as needed
}

.trading-token-footer-container {
  z-index: 2;
  width: 100%;
  height: 295px; // note: this is set dynamically from the footer component

  transition: all 0.35s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 8px;

  &.noTabs {
    height: fit-content;
    padding-top: 24px;
    padding-bottom: 32px; // important for create offchainToken to have enough space at the bottom
  }

  .buysell-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
    width: 100%;

    .buysell-header-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .trading-token-footer-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;

    // todo: animate buttons in when footer gets minimized
    transition: all 0.35s ease;

    .trading-token-footer-button {
      width: 50%;
      height: 52px;

      &.buy {
        background-color: #09d0a8;
        font-size: 16px;
        color: white;
      }
      &.sell {
        background-color: #d00948;
        font-size: 16px;
        color: white;
      }
    }
  }

  .trading-token-footer-header {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;

    &.sell-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      height: auto;

      .trading-token-footer-title {
        flex: 1;
        text-align: left;
        padding-left: 5px;
      }

      .trading-token-footer-close {
        width: 32px;
        height: 32px;
      }

      .trading-token-footer-form-area {
        flex-basis: 100%;
        margin-top: 16px;
      }
    }

    .tabs-wrapper {
      position: relative;
      width: 100%;

      .trading-token-footer-close {
        position: absolute;
        top: 0px;
        right: 0;
        width: 32px;
        height: 32px;
        z-index: 1;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .trading-token-footer-title {
      font-family: PPPangramSansRounded-Bold;
      font-size: 20px;
    }
  }

  // -------------------------

  .trading-token-footer-form-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    width: 100%;

    // header (balance + slippage)

    .buysell-header {
      .buysell-header-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .buysell-header-balance {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;

          margin-left: 4px; // visual correction

          .buysell-header-balance-label {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 12px;
            color: #777;
          }

          .buysell-header-balance-value {
            font-family: PPPangramSansRounded-Bold;
            font-size: 12px;
            color: white;
          }

          .buysell-header-balance-icon {
            width: 16px;
            height: 16px;
            overflow: hidden;
            border-radius: 90px;
            margin-top: -3px;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .buysell-header-slippage {
          font-family: PPPangramSansRounded-Bold;
          font-size: 12px;
          color: white;

          margin-right: 4px; // visual correction

          visibility: hidden;
        }
      }

      .trading-token-footer-form {
        width: 100%;
        height: 48px;
        border-radius: 90px;
        padding: 8px 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 4px;

        width: 100%;

        background-color: #232323;
        // outline: 2px solid #343434;
        // outline-offset: -2px;

        .currency-type {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;

          height: 20px;
          margin-left: 4px;

          .currency-type-label {
            font-family: PPPangramSansRounded-SemiBold;
            font-size: 14px;
            color: white;
          }

          .currency-type-icon {
            width: 20px;
            height: 20px;
            overflow: hidden;
            border-radius: 90px;
            margin-top: -3px;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    .trading-token-footer-presets {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      gap: 4px;

      .trading-token-footer-preset {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 36px;
        background-color: #000;
        border: 1px solid #333;
        border-radius: 90px;
        padding: 8px;

        font-family: PPPangramSansRounded-Semibold;
        font-size: 14px;

        &.selected {
          border: 1px solid #fff;
        }
      }
    }

    .trading-token-footer-fees {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 4px;

      &.centered {
        justify-content: center;
      }

      .trading-token-footer-slippage {
        font-family: PPPangramSansRounded-Semibold;
        font-size: 12px;
      }
    }

    .button-buy {
      width: 100%;
      height: 52px;

      // button

      .content-buy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        font-family: PPPangramSansRounded-Bold;
        font-size: 14px;

        color: black;

        .icon {
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 90px;
          margin-top: -3px;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }

      &.no-coins {
        margin-top: -25px;
      }
    }
  }

  // -------------------------

  .items-area {
    margin-top: 12px;
  }

  .trading-token-footer-fee-message-container {
    position: relative;
    width: 100%;

    .trading-token-footer-fee-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

      width: 100%;

      font-family: PPPangramSansRounded-Medium;
      font-size: 13px;
      color: #777;

      .coin {
        width: 16px;
        height: 16px;
        margin-top: -1px;
        img {
          width: 100%;
        }
      }

      .label {
        font-family: PPPangramSansRounded-Medium;
        font-size: 13px;
        color: white;

        &.blue {
          color: #0098ea; // blue
        }

        &.grey {
          color: #777; // blue
        }

        &.icon {
          margin-top: 2px;
        }
      }
    }
  }

  // -------------------------
}

// =================================================
// tooltip with info about fees and transactions

.tooltip-fees {
  pointer-events: auto;
  z-index: 1;
  position: absolute;
  top: -90px; // 182px;

  background-color: white;
  border-radius: 16px;

  padding: 12px;
  padding-top: 14px;
  padding-right: 40px;

  .tooltip-fees-close {
    pointer-events: auto;
    position: absolute;
    top: 10px;
    right: 8px;
  }

  .tooltip-tail {
    position: absolute;
    bottom: -4px;
    left: 37px;
    width: 16px;
    height: 16px;
    background-color: white;
    transform: rotate(45deg);
  }

  .tooltip-fees-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 4px;

    .tooltip-fees-label {
      font-family: PPPangramSansRounded-Medium;
      font-size: 13px;
      color: black;
    }
  }
}

// =================================================
