.trading-create-row {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 6px;

  position: relative;
  opacity: 1;
  width: 100%;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;

    font-family: PPPangramSansRounded-Medium;
    font-size: 13px;
    color: #777;
    text-align: left;

    .title {
      color: #777;
    }
    .required {
      font-size: 14px;
      color: red;
    }
  }

  .trading-create-input {
    position: relative;
    border-radius: 16px; // 90px;

    font-family: PPPangramSansRounded-Medium;
    font-size: 14px;
    color: white;

    width: 100%;

    height: 48px;

    margin: 0;
    padding: 0 16px;
    padding-right: 48px;
    box-sizing: border-box;

    background-color: #232323;
    border: unset;

    // attempt at enabling text selection
    // spoiled alert: not working
    user-select: text;
    -webkit-user-select: text;
    -webkit-user-callout: default;
    -webkit-touch-callout: default;
    -webkit-highlight: default;
    -webkit-user-modify: read-write; /* Ensure content is modifiable */
    pointer-events: auto; /* Ensure pointer events are enabled */
    touch-action: manipulation; /* Allow touch actions */
    appearance: none;
    -webkit-appearance: none; /* Reset appearance for iOS */

    &.image {
      height: 200px;
      background-color: pink;
      pointer-events: none;
    }
  }

  .icon-tick {
    position: absolute;
    top: 37px;
    right: 22px;

    width: 12px;
    height: 8px;
    img {
      width: 12px;
      height: 8px;
    }
  }
}
