// =============================================
// container for whole tabs and lists with items

.trading-tabs-container {
  width: 100%;
  // overflow-x: hidden;
  margin-top: -8px;
}

// =============================================
// container for list with items on each tab

.items-area {
  margin-top: 12px;

  .trading-items-list {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    // background-color: green;

    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 4px;
  }

  .items-list {
    margin-top: 8px;
    margin-bottom: 8px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    padding-bottom: 8px;
  }
}

// ====================================================================
